import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import './ThreeDModelScreen.scss';
import ModelGridItem, {
  Model,
} from '../../components/ModelGridItem/ModelGridItem.react';
import Border from '../../components/Border.react';
import UploadDialog from './UploadDialog.react';
import { ThreeDModelFormProvider } from './ThreeDModelFormProvider.react';

const RequestOptions = {
  headers: { Authorization: localStorage.getItem('token') },
};
export default function ThreeDModelScreen() {
  const [models, setModels] = useState<Model[]>([]);
  const [loading, setLoading] = useState(true);
  // const [threeDModelFiles, setThreeDModelFiles] = useState<FileList | null>(
  //   null,
  // );
  // const [model, setModel] = useState<Model | null>(null);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  // const [loadingItem, setLoadingItem] = useState<{
  //   index: number;
  //   loading: boolean;
  // }>({ index: -1, loading: false });
  console.log(`process.env`, process.env);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/threeDModels`, RequestOptions)
      .then((response) => {
        setModels(response.data.models);
        setLoading(false);
      });
  }, []);
  // const inputUploadRef = useRef<any>(null);
  // const handleUpload = useCallback(
  //   async (event) => {
  //     setOpenUploadDialog(true);
  //     if (event.target.files) {
  //       // setThreeDModelFiles(event.target.files);
  //       const formData = new FormData();
  //       Array.from(event.target.files as FileList).forEach((file) => {
  //         formData.append('models', file);
  //       });
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}api/threeDModels`,
  //         formData,
  //         {
  //           headers: { Authorization: localStorage.getItem('token') },
  //         },
  //       );
  //       console.log('response', response);
  //       setModel(response.data.model);
  //     }

  //     setModels([...models]);
  //     setLoadingItem({ index: models.length - 1, loading: true });
  //   },
  //   [models],
  // );

  const handleDelete = useCallback(
    (id: string, index: number) => {
      if (window.confirm('Are you sure??')) {
        setLoading(true);
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}api/threeDModels/${id}`,
            RequestOptions,
          )
          .then((w) => w.data)
          .then((w) => {
            if (w.success) {
              models.splice(index, 1);
              setModels([...models]);
              setLoading(false);
            } else {
              window.alert('Something went wrong');
            }
          });
      }
    },
    [models],
  );
  return (
    <div id="content">
      {/* <ThreeDModelFormProvider modelFiles={threeDModelFiles} model={model}> */}
      <UploadDialog
        open={openUploadDialog}
        onOpenToggle={() => setOpenUploadDialog(!openUploadDialog)}
        onModelCreated={(newModel: Model) => setModels([...models, newModel])}
      />
      {/* </ThreeDModelFormProvider> */}
      <div className="header">
        <h1>MY 3D CONTENT</h1>
        <div>
          {/* <input
            onChange={handleUpload}
            ref={inputUploadRef}
            type="file"
            multiple
            style={{ display: 'none' }}
          /> */}
          <button
            onClick={() => {
              setOpenUploadDialog(true);
            }}
          >
            Upload Content
          </button>
        </div>
      </div>
      <div className="subHeader">
        <p>
          3D Content can be accessed for AR Broadcasts on the Scenic mobile app.
        </p>
      </div>

      {!loading && models.length === 0 ? (
        <div className="nocontent">
          <div className="border">
            <img src="/no_3d_models_icon.png" alt="" />
            <p>you do not currently have any 3d content uploaded</p>
          </div>
        </div>
      ) : (
        <>
          <Border />
          <Grid container spacing={1} sx={{ backgroundColor: 'black' }}>
            {loading && (
              <div className="loading">
                <CircularProgress color="success" />
              </div>
            )}
            {models.map((model, index) => (
              <ModelGridItem
                key={model._id}
                index={index}
                model={model}
                onDelete={handleDelete}
              />
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}
