import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useThreeDModelForm } from './ThreeDModelFormProvider.react';
import { Model } from '../../components/ModelGridItem/ModelGridItem.react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import LoadingOverlay from '../../components/Loading/LoadingOverlay';
import { Box, Button, Modal, Typography } from '@mui/material';
import { WarningModal } from './WarningModal.react';

export type FormModel = {
  name: string;
  description: string;
  tags: string[];
  privacy: 'private' | 'public' | '';
};

export default function ThreeDModelDialogContent({
  onModelCreated,
  onOpenToggle,
}: {
  onModelCreated: (newModel: Model) => any;
  onOpenToggle: () => any;
}) {
  // const [uploadedModel, setUploadedModel] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showLargeFileWarning, setShowLargeFileWarning] = useState(false);
  const { model } = useThreeDModelForm();
  const {
    isLoaded,
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    unload,
  } = useUnityContext({
    loaderUrl: '/build.loader.js',
    dataUrl: '/build.data',
    frameworkUrl: '/build.framework.js',
    codeUrl: '/build.wasm',
    webglContextAttributes: { preserveDrawingBuffer: true },
  });

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    sendMessage('[Bridge]', 'getAuthToken', localStorage.getItem('token'));
  }, [isLoaded, sendMessage]);

  const handleLargeFile = useCallback(() => {
    setShowLargeFileWarning(true);
  }, []);
  const handleSubmissionSuccess = useCallback(
    async (stringData) => {
      setUploading(true);
      const {
        title,
        description,
        privacy,
        hasAnimation,
        modelFile,
        thumbnailFile,
      } = JSON.parse(stringData);
      const thumbnailBase64Response = await fetch(
        'data:text/plain;base64,' + thumbnailFile,
      );
      const thumbnailBlob = await thumbnailBase64Response.blob();
      const modelsBase64Response = await fetch(
        'data:text/plain;base64,' + modelFile,
      );
      const modelBlob = await modelsBase64Response.blob();
      const formData = new FormData();
      formData.append('name', title);
      formData.append('description', description);
      formData.append('privacy', privacy);
      formData.append('thumbnail', thumbnailBlob, 'thumbnail.png');
      formData.append('models', modelBlob);
      formData.append('hasAnimation', hasAnimation);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/threeDModels`,
        formData,
        {
          headers: { Authorization: localStorage.getItem('token') },
        },
      );
      setUploading(false);
      console.log('response', response);
      onModelCreated(response.data.model);
      await unload();
      onOpenToggle();
    },
    [onModelCreated, onOpenToggle, unload],
  );

  useEffect(() => {
    addEventListener('SetModel', handleSubmissionSuccess);
    addEventListener('SelectedLargeFile', handleLargeFile);
    return () => {
      removeEventListener('SetModel', handleSubmissionSuccess);
      removeEventListener('SelectedLargeFile', handleLargeFile);
    };
  }, [
    addEventListener,
    removeEventListener,
    handleSubmissionSuccess,
    handleLargeFile,
  ]);

  return (
    <>
      <WarningModal
        open={showLargeFileWarning}
        onClose={() => setShowLargeFileWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Typography variant="h6">
            The file you selected is too large!
          </Typography>
          <Typography>
            Make sure your upload is <br /> less than 20MB.
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setShowLargeFileWarning(false)}
          >
            OK
          </Button>
        </Box>
      </WarningModal>
      {uploading && <LoadingOverlay />}
      <Unity
        unityProvider={unityProvider}
        style={{ width: 960, height: 600 }}
      />
    </>
  );
}
