import React from 'react';
import './LoadingOverlay.css'; // Import your CSS file for styling

const LoadingOverlay = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <img
          src="/purple_loading_spinner.png"
          alt="Spinning"
          className="spinner"
        />
        <span className="loading-text">Loading</span>
      </div>
    </div>
  );
};

export default LoadingOverlay;
