import { Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WarningModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none', // To remove the default outline
  backgroundColor: 'rgba(34, 34, 34, 0.7)',

  '& .MuiBox-root': {
    backgroundColor: '#222222', // Background color
    borderRadius: '20px', // Border radius
    padding: theme.spacing(3),
    textAlign: 'center',

    '& .MuiTypography-root': {
      color: 'white', // Text color
      marginBottom: theme.spacing(2),
    },

    '& .MuiButton-root': {
      backgroundColor: 'transparent', // No background
      color: 'white', // Text color
      border: '2px solid white', // White border
      borderRadius: '20px', // Border radius
      width: '100%', // Take up all available width
    },
  },
}));
