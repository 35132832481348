import React from 'react';
import Layout from './components/layout/Layout';
import ThreeDModelScreen from './views/ThreeDModel/ThreeDModelScreen';
import SignInScreen from './views/SignIn/SignInScreen';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { isLoggedIn } from './components/security/AuthService';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

const theme = createTheme({
  typography: {
    fontFamily: 'Arial',
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {},
      },
    },
  },
  palette: {
    primary: {
      main: '#daacf2',
    },
    secondary: {
      main: '#bbbabb',
    },
    background: {
      default: '#1b1b1b',
      paper: '#1b1b1b',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
      disabled: '#ffffff',
    },
    error: {
      main: '#ff0000',
    },
  },
});

function App() {
  const history = createBrowserHistory();

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Router history={history}>
          {!isLoggedIn() ? (
            <SignInScreen />
          ) : (
            <Switch>
              <Route path="/login">
                <SignInScreen />
              </Route>
              <Route path="/models">
                <ThreeDModelScreen />
              </Route>
              <Route path="/">
                <Redirect to="/models"></Redirect>
              </Route>
            </Switch>
          )}
        </Router>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
