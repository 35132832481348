import jwtDecode from 'jwt-decode';

export function isLoggedIn(): boolean {
  const serviceToken = localStorage.getItem('token');
  if (!serviceToken) {
    return false;
  } else {
    return true;
  }
}

export function getUser(): { username?: string; id?: string } {
  const serviceToken = localStorage.getItem('token');
  if (!serviceToken) {
    return {};
  } else {
    const decoded = jwtDecode(serviceToken) as any;
    return { username: decoded.username, id: decoded.id };
  }
}
