import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ThreeDModelDialogContent from './ThreeDModelDialogContent.react';
import { Model } from '../../components/ModelGridItem/ModelGridItem.react';

type Props = {
  onModelCreated: (newModel: Model) => any;
  onOpenToggle: () => any;
  open: boolean;
};

const ScenicDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  // width: '600px',
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: '8px',
    minWidth: '1020px',
    '& .MuiDialogContent-root': {
      padding: '20px 24px',
    },
  },
}));

const ScenicDialogTitle = styled(DialogTitle)<DialogTitleProps>(
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    padding: '12px 24px',
  }),
);

const IconButtonWithNoHoverEffect = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
);

export default function UploadDialog({
  onModelCreated,
  onOpenToggle,
  open,
}: Props) {
  return (
    <ScenicDialog open={open}>
      <ScenicDialogTitle>
        Upload New 3D Content
        <IconButtonWithNoHoverEffect
          onClick={onOpenToggle}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <img src="/popup_x_icon.png" alt="Close Dialog" height="32" />
        </IconButtonWithNoHoverEffect>
      </ScenicDialogTitle>
      <DialogContent>
        <ThreeDModelDialogContent
          onOpenToggle={onOpenToggle}
          onModelCreated={onModelCreated}
        />
      </DialogContent>
    </ScenicDialog>
  );
}
