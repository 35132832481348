import axios from 'axios';
import React, { useCallback, useState } from 'react';
import './SignInScreen.scss';
export default function SignInScreen() {
  const [form, setForm] = useState<{ username: string; password: string }>({
    username: '',
    password: '',
  });
  const [message, setMessage] = useState('');
  const handleChange = useCallback(
    (arg: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [arg.target.name]: arg.target.value });
    },
    [form],
  );
  const handleSubmit = useCallback(() => {
    setMessage('');
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/auth/login`, form)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('token', res.data.jwtAccessToken);
          window.location.replace('/models');
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        setMessage(
          err.response.data.message == 'Incorrect password' ? '2' : '1',
        );
      });
  }, [form]);
  return (
    <div id="signin">
      <div className="header">
        <h1>
          <img src="/logo.png" alt="" />
        </h1>
      </div>
      <div className="form">
        <label>SCENIC USERNAME</label>
        <input
          type="text"
          value={form.username}
          name="username"
          onChange={handleChange}
        />
        {message == '1' && (
          <div className="error">
            <img src="/error.png" />
            <label>
              {' '}
              Username Not Found. Register by downloading the{' '}
              <strong>Senic</strong> mobile app.
            </label>
          </div>
        )}
        <label>SCENIC PASSWORD</label>
        <input
          type="password"
          value={form.password}
          name="password"
          onChange={handleChange}
        />
        {message == '2' && (
          <div className="error2">
            <img src="/error.png" />
            <label>
              {' '}
              Wrong password. You can reset your password on{' '}
              <strong>Scenic AR</strong> mobile app.
            </label>
          </div>
        )}
        <button onClick={handleSubmit}>SIGN IN</button>
      </div>
    </div>
  );
}
