import { Box } from '@mui/system';

export default function Border() {
  return (
    <Box
      sx={{
        borderStyle: 'solid',
        borderWidth: '2px',
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        marginBottom: '1rem',
        borderImage: `linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.7) 30%, rgba(255, 255, 255, 0.7) 70%, rgba(255, 255, 255, 0.05) 100%) 1`,
        width: '100%',
      }}
    />
  );
}
