import { createContext, ReactNode, useContext, useMemo } from 'react';
import { Model } from '../../components/ModelGridItem/ModelGridItem.react';

type ThreeDModelFormContextType = {
  onUploadFinalize: ({ formData }: { formData: {} }) => void;
  modelFiles: FileList | null;
  model: Model | null;
};

const ThreeDModelFormContext = createContext<ThreeDModelFormContextType>({
  modelFiles: null,
  model: null,
  onUploadFinalize: ({ formData }) => new Promise((resolve) => {}),
});

export function ThreeDModelFormProvider({
  children,
  modelFiles,
  model,
}: {
  children: ReactNode;
  modelFiles: FileList | null;
  model: Model | null;
}) {
  const threeDModelFormContext = useMemo<ThreeDModelFormContextType>(
    () => ({
      modelFiles,
      model,
      onUploadFinalize: ({ formData }) => {
        console.log('all data', { ...formData, ...{ file: modelFiles } });
      },
    }),
    [modelFiles, model],
  );

  return (
    <ThreeDModelFormContext.Provider value={threeDModelFormContext}>
      {children}
    </ThreeDModelFormContext.Provider>
  );
}

export const useThreeDModelForm = () => useContext(ThreeDModelFormContext);
